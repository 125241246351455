<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t('label_agreements')}}</b-breadcrumb-item>
      </div>
    </div>


    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          {{ $t('label_filters') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col
                  cols="12"
                  md="3"
                  class=" mb-1 date-range-col"
          >
            <label>{{ $t('label_date_of_creating') }}</label>

            <div class="date-range-filter mb-2">
              <custom-date-picker
                      class="date--picker no-label"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="filterData.dateFromFilter"
                      @input="(val) => filterData.dateFromFilter = val"

              >
                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
              </custom-date-picker>

              <div class="date-separator" />
              <custom-date-picker
                      class="date--picker no-label"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="filterData.dateToFilter"
                      @input="(val) => filterData.dateToFilter = val"

              >
                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
              </custom-date-picker>

            </div>
            <label>{{ $t('label_sort_by_payment_dates') }}</label>
            <div class="date-range-filter">
              <custom-date-picker
                      class="date--picker no-label"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="filterData.paymentFromAt"
                      @input="(val) => filterData.paymentFromAt = val"
              >
                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
              </custom-date-picker>

              <div class="date-separator" />
              <custom-date-picker
                      class="date--picker no-label"
                      :locale="$store.state.appConfig.locale"
                      :placeholder="$t('label_DD-MM-YYYY')"
                      :value="filterData.paymentToAt"
                      @input="(val) => filterData.paymentToAt = val"
              >
                <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
              </custom-date-picker>
            </div>

          </b-col>
          <b-col
                  cols="12"
                  md="3"
                  class="mb-md-0 mb-2"
          >
            <label>{{ $t('label_agreement_status') }}</label>
            <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="filterData.statusFilter"
                    :options="agreement_statuses"
                    class="w-100"
                    :clearable="true"
                    :placeholder="$t('label_select')"
                    :reduce="val => val.id"
            >
              <template v-slot:option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.translation_index) }}</span>
              </template>
              <template v-slot:selected-option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.translation_index) }}</span>
              </template>
            </v-select>
            <div class="mb-2"></div>
            <label>{{ $t('label_payment_status') }}</label>
            <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="filterData.paymentStatusFilter"
                    :options="payment_statuses"
                    class="w-100"

                    :placeholder="$t('label_select')"
                    :reduce="val => val.name_alias"

            >
              <template v-slot:option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.name) }}</span>
              </template>
              <template v-slot:selected-option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.name) }}</span>
              </template>
            </v-select>
          </b-col>
          <b-col
                  cols="12"
                  md="3"
                  class="mb-md-0 mb-2"
          >

            <label>{{ $t('label_consultant') }}</label>
            <infinity-scroll

                    selected-prop="id"
                    url="/consultant"
                    :placeholder="$t('label_select')"
                    v-model="filterData.consultantFilter"
            >
              <template #label="{item}">{{ item.name }}</template>

            </infinity-scroll>
          </b-col>
          <b-col
                  cols="12"
                  md="3"
                  class="mb-md-0 mb-2"
          >
            <label>{{ $t('label_recommended') }}</label>

            <infinity-scroll

                    selected-prop="id"
                    url="/agent"
                    :placeholder="$t('label_select')"
                    v-model="filterData.agentFilter"
            >
              <template #label="{item}">{{ item.name }}</template>

            </infinity-scroll>
          </b-col>


        </b-row>
      </b-card-body>
    </b-card>

    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
                    v-model="tableData.perPage"
                    :searchable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tableData.perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <column-chooser v-model="selectedColumns" :not-touch="['id']"  :columns="columnsDefs"></column-chooser>

          </b-col>

          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                      v-model="tableData.searchQuery"
                      type="search"
                      debounce="500"
                      class="d-inline-block mr-1"
                      :placeholder="$t('label_search')+'...'"
              />
              <b-button v-if="$can('edit', 'agreement.tab_details')"
                      :to="{name: 'agreement_add'}"
                      variant="primary"

              >
                <span class="text-nowrap">{{ $t('label_add_agreement') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <!--***********************************************************TABLE*********************************************-->

      <!--sticky-header-->
  <div class="px-2 table-container-wrap">
    <b-table
            ref="table"
            class="position-relative transited-table"
            :busy="isBusy"
            no-border-collapse
            :items="tableItems"
            responsive
            :fields="selectedColumns"
            primary-key="id"
            :sort-by.sync="tableData.sortBy"
            no-local-sorting
            :sort-desc.sync="tableData.sortDesc"
            show-empty
            :tbody-transition-props="{ name: 'flip-list'}"
            :empty-text="$t('label_no_table_data')"
    >

      <template #head()="data">
        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
      </template>

      <template #cell(first_name)="data">

        <div style="min-width: 110px">
          <router-link target="_blank" v-if="$can('view', 'agreement.tab_details')" :to="{ name: 'agreement-details', params: { id: data.item.id } }">
            {{$t('label_agreement') + ' ' + data.item.first_name + ' ' + data.item.last_name }}
          </router-link>
          <template v-else >
            {{$t('label_agreement') + ' ' + data.item.first_name + ' ' + data.item.last_name }}
          </template>
        </div>
      </template>

       <template #cell(order_subject)="data">
         <template v-if="data.item.order_subject">
           {{data.item.order_subject.translation_index? $t(data.item.order_subject.translation_index) : data.item.order_subject.name }}
         </template>
      </template>

      <template #cell(value_of_claims)="data">
        <b-badge variant="light-primary"
                 size="sm"
                 v-b-tooltip
                 :title="data.item.description_of_claims || ''"
        >
          {{ data.item.value_of_claims ? data.item.value_of_claims.formatPrice() : 0 }}
        </b-badge>
      </template>

      <template #cell(firm_name)="data">
        <router-link target="_blank" v-if="$can('view', 'contact.tab_details')" :to="{ name: 'contact-details', params: { id: data.item.id } }">
          <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
          <feather-icon v-else icon="MinusIcon" />
        </router-link>
        <template v-else>
          <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
          <feather-icon v-else icon="MinusIcon" />
        </template>
      </template>

      <template #cell(contact)="data">
        <div style="min-width: 110px">
          <template v-if="data.item.contact">
            <router-link target="_blank" v-if="$can('view', 'contact.tab_details')" :to="{ name: 'contact-details', params: { id: data.item.contact.id } }">
              {{data.item.contact.first_name + ' ' + data.item.contact.last_name }}
            </router-link>
            <template v-else >
              {{data.item.contact.first_name + ' ' + data.item.contact.last_name }}
            </template>
          </template>
          <template v-else>{{data.item.first_name + ' ' + data.item.last_name }}</template>
        </div>
      </template>
      <template #cell(agent)="data">

        <template v-if="data.item.agent">
          <router-link v-if="$can('view', 'agent.tab_details')" :to="{ name: 'agent-details', params: { id: data.item.agent.id } }">
            {{data.item.agent.name }}
          </router-link>
          <template v-else >
            {{data.item.agent.name }}
          </template>
        </template>
        <template v-else>--</template>

      </template>

      <template #cell(is_paid)="data">
        <!--:variant="'light' + (data.item.payments) ? ((data.item.is_paid) ? 'success' : (!data.item.in_time ? 'info' : 'default')) : 'default'"-->
        <span class="text-capitalize">
                    {{(data.item.payments) ? ((data.item.is_paid) ? $t('label_paid') : (!data.item.in_time ? $t('label_unpaid_on_time') : '--')) : '--'}}
              </span>
      </template>

      <template #cell(agreement_status)="data">
        <b-badge
                pill
                :variant="`light-${resolveAgreementStatusVariant(agreement_statuses.findWhere('id', data.item.status_id)?agreement_statuses.findWhere('id', data.item.status_id).name_alias : '')}`"
                class="text-capitalize"
        >
          {{ data.item.agreement_status? $t(data.item.agreement_status.translation_index) : '--' }}
        </b-badge>
      </template>


      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
                dropleft
                boundary="window"
                variant="link"
                no-caret
                @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
                @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
                :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
            />
          </template>



          <template v-if="agreement_statuses.findWhere('id',data.item.status_id) && agreement_statuses.findWhere('id',data.item.status_id).name_alias != 'forward_to_lawyer'">
            <b-dropdown-item disabled>
              <span class="badge badge-light-primary d-block">{{ $t('label_change_status') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'forward_to_lawyer', data.item)">
              <span class="align-middle ml-50">{{ $t('label_forward_to_lawyer') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_paid', data.item)">
              <span class="align-middle ml-50">{{ $t('label_agreement_paid') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'awaiting_signature', data.item)">
              <span class="align-middle ml-50">{{ $t('label_awaiting_signature') }}</span>
            </b-dropdown-item>
<!--            <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_signed', data.item)">-->
<!--              <span class="align-middle ml-50">{{ $t('label_agreement_signed') }}</span>-->
<!--            </b-dropdown-item>-->
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'completing_agreement', data.item)">
              <span class="align-middle ml-50">{{ $t('label_completing_agreement') }}</span>
            </b-dropdown-item>
            <!-- Modal -->
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_paused_status', data.item)">
              <span class="align-middle ml-50">{{ $t('label_agreement_paused_status') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_finished', data.item)">
              <span class="align-middle ml-50">{{ $t('label_agreement_finished') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_conducted', data.item)">
              <span class="align-middle ml-50">{{ $t('label_agreement_conducted') }}</span>
            </b-dropdown-item>
            <!-- Modal -->
            <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_rejected', data.item)">
              <span class="align-middle ml-50">{{ $t('label_agreement_rejected') }}</span>
            </b-dropdown-item>

          </template>

          <b-dropdown-item disabled>
            <div class="divider">
              <div class="divider-text">{{ $t('label_action') }}</div>
            </div>

          </b-dropdown-item>

          <b-dropdown-item :to="{name: 'agreement_edit', params: { id: data.item.id }}">
            <feather-icon icon="EditIcon"/>
            <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
          </b-dropdown-item>


        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
        </b-col>
        <!-- Pagination -->
        <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
                  v-model="tableData.currentPage"
                  :total-rows="tableTotal"
                  :per-page="tableData.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </div>


    </b-card>

  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody, VBTooltip
} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import infinityScroll from '@/views/components/infinityScroll'

    import columnChooser from '@/views/components/columnChooser'

    import { AGREEMENT_PREFIX as PREFIX, resolveAgreementStatusVariant} from './moduleHelper'

    export default {
        components: {
            BCard,
        BCardHeader,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
          BCardBody,
            vSelect,
            columnChooser,
            infinityScroll
        },
      directives: {
        'b-tooltip': VBTooltip,
      },
        data() {
            return {
                PREFIX,
                resolveAgreementStatusVariant,
                editedItem: false,
                isSidebarOpen: false,

                selectedColumns:[],

                columnsDefs: [

                    {label: 'label_agreement', key: 'first_name', sortable: true},
                    {label: 'label_firm_name', key: 'firm_name', sortable: true},
                    {label: 'label_approver', key: 'contact', sortable: true},
                    {label: 'label_date_agreement_conclusion', key: 'created_at', sortable: true, formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }},
                    {label: 'label_contract_termination_date', key: 'termination_at', sortable: true, formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }},
                    {label: 'label_deal_number', key: 'deal_number', sortable: true},
                    {label: 'label_subject_of_the_order', key: 'order_subject', sortable: true},
                    {label: 'label_recommended', key: 'agent', sortable: true},
                  {label: 'label_value_of_claims', key: 'value_of_claims', sortable: true},
                  {label: 'label_status', key: 'is_paid', sortable: true},
                    {label: 'label_to_pay', key: 'sum_to_pay', formatter(v) {
                            return v ? v.formatPrice() : '--'
                        }, sortable: true},
                    {label: 'label_agreement_status', key: 'agreement_status', sortable: true},
                    {label: 'label_action', key: 'actions'}
                ],

                isBusy:false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },

                filterData: {
                    paymentFromAt:null,
                    paymentToAt:null,
                    paymentStatusFilter: null,
                    dateFromFilter: null,
                    dateToFilter: null,
                    consultantFilter: null,
                    agentFilter: null,
                    statusFilter: null,

                },

                agreement_statuses:[],

                payment_statuses:[]
            }
        },
        computed: {
            dataMeta: (context) => {
                return {
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }
            },

        },
        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }

        },

        created() {
            let self = this;
            this.getPageData();

            this.refreshDataTable();

            this.columnsDefs = this.columnsDefs.filter(function (obj) {
                if (obj.key == 'actions' && !self.$can('edit', 'agreement.tab_details')) {
                    return false;
                }
                return true;
            });

        },
        methods: {

            getPageData(){
                this.async('get', '/select_options', {params:{options:['agreement_statuses', 'payment_statuses']}}, function(res){
                    this.agreement_statuses = res.data.agreement_statuses;
                    this.payment_statuses = res.data.payment_statuses;
                });
            },

          async changeContactStatus(module_id, status_alias, deal) {
            const status_id = this.agreement_statuses.findWhere('name_alias', status_alias)?.id
            if (status_id && ['198', '199', '200'].includes(status_id)) {
              this.editedDeal = deal;
              this.newDealStatusId = status_id;
              await this.$bvModal.show('confirm-status-modal');
            } else {
              this.async('put', '/agreement/change_status/' + module_id, {status_alias: status_alias}, function (resp) {
                this.refreshDataTable();
              });
            }
          },

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/'+this.PREFIX, {params: {
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,

                        created_at_from: this.filterData.dateFromFilter,
                        created_at_to: this.filterData.dateToFilter,
                        payment_at_from: this.filterData.paymentFromAt,
                        payment_at_to: this.filterData.paymentToAt,
                        consultant: this.filterData.consultantFilter,
                        agent_id: this.filterData.agentFilter,
                        pay_status_id: this.filterData.paymentStatusFilter,
                        status_id: this.filterData.statusFilter,

                    }}, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;

                    this.isBusy = false;
                });
            },

            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('get', '/'+this.PREFIX+'/'+id+'/delete', {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },

        },

    }
</script>

